/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    shape, string, number, func, bool,
} from 'prop-types';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { compose } from 'recompose';
import { makeStyles } from '@material-ui/core/styles';
import ReactMarkdown from 'react-markdown';
import { bindActionCreators } from 'redux';
import cleanText from '../../../helpers/contentstack/cleanText';
import CTA from '../Buttons/CTA';
import transformRenderer from '../../../helpers/markdown/characterTransformer';
import LinkOrATag from '../../GraphqlComponents/GraphqlCommonComponents/LinkOrATag/LinkOrATag';
import PassportEyebrow from '../PassportBanner/PassportEyebrow';
import { trackEvent as track } from '../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import replaceSpecialChar from '../../../helpers/replaceSpecialChar';
import { getFeatureFlag } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import BlockHeading from '../BlockHeading';

// strips "font-family:" and ";" from value
const parseFont = (font) => font?.match(/:\s(.*?);/, '')?.[1] || null;

const themes = {
    variantButton: {
        default: {
            display: 'block',
        },
        variant_a: {
            display: 'none',
        },
    },
    strokeHideShow: {
        default: {
            display: 'block',
        },
        variant_a: {
            display: 'none',
        },
    },
    variantHeader: {
        default: {
            padding: '0px 16px 0px 0px',
            width: 'auto',
            margin: '5px 0px 10px',
            textAlign: 'left',
            fontSize: '1.5em',
        },
        variant_a: {
            width: '100%',
            margin: 0,
            padding: 40,
            textAlign: 'center',
            paddingMobile: '40px 0 15px',
            textAlignMobile: 'left',
        },
    },
    mainHeaderContainer: {
        variant_a: {
            display: 'block',
        },
    },
};

const useStyles = makeStyles((theme) => ({
    viewButton: (styles) => ({
        padding: '14px 20px 14px',
        backgroundColor: theme.palette.cms?.white || '#ffffff',
        zIndex: 1,
        cursor: 'pointer',
        display: themes.strokeHideShow[styles?.presentation]?.display || 'block',
        [theme.breakpoints.down('md')]: {
            padding: '14px 8px 14px 20px',
        },
        [theme.breakpoints.down(599)]: {
            padding: '14px 0px',
            marginTop: '10px',
            '& button': {
                width: '130px',
                padding: '8px 2px 9px',

            },
        },
    }),
    mainHeadingRich: (styles) => ({
        fontFamily: parseFont(styles?.font_family),
        fontSize: styles?.font_size || '2.2em',
        fontWeight: styles?.fontWeight || 400,
        color: styles?.font_color?.color || '#000000',
        '& p': {
            margin: '0',
        },
        '& h1,h2,h3,h4,h5,h6': {
            margin: '0 0 10px 0',
        },
    }),
    mainHeadingRichWithGrid: (styles) => ({
        fontFamily: parseFont(styles?.font_family),
        fontSize: styles?.font_size || '2.2em',
        fontWeight: styles?.fontWeight || 400,
        color: styles?.font_color?.color || '#000000',
        '& p': {
            margin: '0',
        },
        '& span h6': {
            margin: '0px auto',
        },
    }),
    mainHeading: (styles) => ({
        zIndex: 1,
        padding: themes.variantHeader[styles?.presentation]?.padding || '0px 16px 0px 0px',
        width: themes.variantHeader[styles?.presentation]?.width || 'auto',
        backgroundColor: styles?.background_color?.color || theme.palette.cms?.white || '#ffffff',
        fontWeight: styles?.fontWeight || 400,
        fontSize: styles?.font_size || '2.2em',
        fontFamily: parseFont(styles?.font_family),
        margin: themes.variantHeader[styles?.presentation]?.margin || '20px 0px 10px',
        textAlign: styles?.alignment || 'left',
        textTransform: styles?.text_transform || 'none',
        color: styles?.font_color?.color || '#000000',
        '& a': {
            textDecoration: 'none',
            color: styles?.font_color?.color || '#000000',
            '& p': {
                margin: '0',
            },
        },
        '& sup': {
            fontSize: '.6em',
        },
        [theme.breakpoints.down('md')]: {
            paddingLeft: '8px',
        },
        [theme.breakpoints.down(599)]: {
            marginBottom: '5px',
            fontSize: styles?.font_size || '1.5em',
            lineHeight: '1em',
            paddingLeft: '0px',
            padding: themes.variantHeader[styles?.presentation]?.paddingMobile || '0px 16px 0px 0px',
            textAlign: themes.variantHeader[styles?.presentation]?.textAlignMobile || styles?.text_alignment || 'left',
        },
    }),

    headerContainer: (styles) => ({
        display: themes.mainHeaderContainer[styles?.presentation]?.display || '',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'relative',
        [theme.breakpoints.down(599)]: {
            flexDirection: 'row',
            padding: '0px 5px',
        },
        '& a': {
            color: theme.palette.cms?.lightBlack || '#222222',
            textDecoration: 'none',
        },
        '& a h2': {
            margin: 0,
        },
    }),
    backgroundStroke: (styles) => ({
        '&:before': {
            top: '50%',
            display: themes.strokeHideShow[styles?.presentation]?.display || 'block',
            left: '0',
            right: '0',
            width: '100%',
            height: '1px',
            content: '""',
            zIndex: '1',
            position: 'absolute',
            background: theme.palette.cms?.horizentalLineBorder || '#dedede',
            [theme.breakpoints.down(599)]: {
                visibility: 'hidden',
            },
        },
    }),
    copy: {
        width: '56%',
        [theme.breakpoints.down(1024)]: {
            width: '70%',
        },
        [theme.breakpoints.down(599)]: {
            width: '100%',
        },
        '& p': {
            margin: '0px',
        },
    },
    headerVarient: {
        width: '100%',
        margin: 0,
        padding: '40px 0px 10px 0px',
        textAlign: 'center',
        '& a': {
            color: theme.palette.cms?.lightBlack || '#222222',
            textDecoration: 'none',
        },
        [theme.breakpoints.down(768)]: {
            lineHeight: '1.1',
        },
        [theme.breakpoints.down(599)]: {
            padding: '40px 0 15px',
            fontSize: 45,
            textAlign: 'left',
        },
    },
    headerDivider: {
        '&:before': {
            visibility: 'hidden',
        },
    },
    viewButtonVarient: {
        visibility: 'hidden',
    },
    subHeaderVarient: {
        width: '100%',
        textAlign: 'center',
    },
    buttonAlign: {
        display: 'flex',
    },
    subHeaderLink: (styles) => ({
        width: '100%',
        textAlign: 'center',
        [theme.breakpoints.up(599)]: {
            display: themes.variantButton[styles?.presentation]?.display || 'block',
        },
        [theme.breakpoints.down(599)]: {
            backgroundColor: `${theme.palette?.primary?.main || '#2F2F2F'}`,
            color: `${theme.palette.cms?.white || '#FFFFFF'}`,
            textDecoration: 'none',
            textAlign: 'center',
            width: 139,
            padding: '14px 0px',
            margin: '20px 0px',
            position: 'relative',
            zIndex: '1',
            '&:after, &:before': {
                width: '100%',
                height: '19px',
                margin: 'auto',
                content: "''",
                display: 'block',
                position: 'absolute',
                background: `${styles?.background_color?.color || theme.palette.cms?.buttonBorderColor || '#F1EDEE'}`,
                borderRadius: '22%',
            },
            '&:before': {
                top: '-17px',
                left: 0,
                right: 0,
            },
            '&:after': {
                bottom: '-17px',
                left: 0,
                right: 0,
            },
        },
        '& a': {
            color: theme.palette.cms?.lightBlack || '#222222',
            fontWeight: 'bold',
            letterSpacing: 1,
            [theme.breakpoints.down(599)]: {
                color: theme.palette.cms?.white || '#ffffff',
                textDecoration: 'none',
            },
        },
    }),
    ctaButton: {
        '& > div': {
            [theme.breakpoints.down(600)]: {
                width: 'max-content',
                alignItems: 'center',
            },
            '& svg': {
                [theme.breakpoints.down(600)]: {
                    width: '0.8em',
                    paddingTop: 0,
                },
            },
        },
    },
}));

const  NupV2Header = ({
    headerSection,
    headingStyling,
    showPassportEyebrow,
    eyebrowCopy,
    variantStyle,
    trackEvent,
    entry,
    backgroundColor,
}) => {
    const {
        heading_rich = '', heading = '', linking = {}, copy = '', copy_rich = '', background_stroke = false,
    } = headerSection;
    const isVariantA = entry?.some((block) => block?.presentation_style);
    const stylesObj = { ...headingStyling }; // needed to update the immutable
    stylesObj.presentation = variantStyle || 'default';
    stylesObj.alignment = headerSection?.alignment;
    stylesObj.background_color = backgroundColor?.background_color;
    const isVariantSubHeader = linking?.link || '';
    const classes = useStyles(stylesObj);

    // || heading = old field to be deprecated in 7312
    const headingCopy = heading_rich || heading;
    const ffHasHomepageGridStructure = useSelector(getFeatureFlag('has-homepage-grid-structure'));

    const printHeading = () => {
        let head = heading ? <BlockHeading heading={replaceSpecialChar(heading, true)} /> : '';
        if (!heading && cleanText(heading_rich)) head = <div className={ffHasHomepageGridStructure ? classes.mainHeadingRichWithGrid : classes.mainHeadingRich}><BlockHeading heading={cleanText(heading_rich)} /></div>;
        return head;
    };

    return (
        <Grid container className={classes.subCollectionHeader} data-testid="nupheading">
            <Grid item xs={12}>
                {showPassportEyebrow && (
                    <div className={classes.passportEyebrow}>
                        <PassportEyebrow copy={eyebrowCopy} />
                    </div>
                )}
                <div className={`${classes.headerContainer} ${background_stroke ? classes.backgroundStroke : ''} ${linking?.link?.href ? classes.buttonAlign : ''}`}>
                    { linking?.link?.href
                        ? (
                            <>
                                {heading_rich && (
                                    <Link
                                        to={linking?.link?.href}
                                        title={heading}
                                        onClick={() => {
                                            trackEvent({
                                                eventCategory: linking?.tracking_event_category || '',
                                                eventAction: linking?.tracking_event_action || '',
                                                eventLabel: linking?.tracking_event_label || '',
                                            });
                                        }}
                                    >  <BlockHeading heading={headingCopy} />
                                    </Link>
                                )}
                                {isVariantA && (
                                    <div className={classes.subHeaderLink}>
                                        <LinkOrATag
                                            href={isVariantSubHeader?.href}
                                            tracking_event_category={linking?.tracking_event_category}
                                            tracking_event_action={linking?.tracking_event_action}
                                            tracking_event_label={linking?.tracking_event_label}
                                        >
                                            {isVariantSubHeader?.title}
                                        </LinkOrATag>
                                    </div>
                                )}
                                {linking?.link?.href && (
                                    <div className={classes.viewButton}>
                                        <CTA
                                            copy={linking?.link?.title}
                                            href={linking?.link?.href}
                                            title={linking?.link?.title}
                                            tracking_event_category={linking?.tracking_event_category}
                                            tracking_event_action={linking?.tracking_event_action}
                                            tracking_event_label={linking?.tracking_event_label}
                                            className={classes.ctaButton}
                                        />
                                    </div>
                                )}
                            </>
                        )
                        : printHeading() }
                </div>
                {/* TODO: Delte rendering copy once it's confirmed that all heading copy if transferred to the line below it renders copy_rich instead */}
                {copy ? <div className={classes.copy}> <ReactMarkdown renderers={transformRenderer} source={copy} escapeHtml={false} /> </div> : null}
                {copy_rich ? (
                    <div className={classes.copy}>
                        <ReactMarkdown renderers={transformRenderer} source={copy_rich?.replace(/<p>|<\/p>/g, '')} escapeHtml={false} />
                    </div>
                ) : null}
            </Grid>
        </Grid>
    );
};

NupV2Header.propTypes = {
    variantStyle: bool,
    headerSection: shape({
        heading: string.isRequired,
    }),
    headingStyling: shape({
        font_family: string,
        fontSize: number,
        font_weight: string,
        font_color: shape({
            color: string,
        }),
    }),
    backgroundColor: shape({
        color: string,
    }),
    entry: shape({
        presentation_style: string,
    }).isRequired,
    showPassportEyebrow: bool,
    eyebrowCopy: string,
    trackEvent: func.isRequired,
};
NupV2Header.defaultProps = {
    headingStyling: {},
    headerSection: {},
    backgroundColor: {},
    showPassportEyebrow: false,
    variantStyle: false,
    eyebrowCopy: '',
};
const mapDispatchToProps = (dispatch) => ({
    trackEvent: bindActionCreators(track, dispatch),
});

const enhance = compose(
    connect(null, mapDispatchToProps),
);
export default enhance(NupV2Header);
